/*=========================================================================================
  File Name: moduleCourseClassActions.js
  Description: CourseClass Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js";
export default {
  addItem({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("Clients/AddBalanceUserAfterApprovedHisRequest", item)
        .then(response => {
          debugger;
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          debugger;
          reject(error);
        });
    });
  },

  CaceleClientRequest({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("Clients/CancelCleintAddMonyRquest", item)
        .then(response => {
          debugger;
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          debugger;
          reject(error);
        });
    });
  },

  fetchAllUserBalanceRequestList({ commit }) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("/Clients/GetAllUserWalletAddAmountRequests")
        .then(response => {
          if (response.status == 200) {
            debugger;
            commit("SET_UserBalanceRequest", response.data);
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  AddBalanceUserAfterApprovedHisRequest({ commit }){
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("/Clients/AddBalanceUserAfterApprovedHisRequest")
        .then(response => {
          if (response.status == 200) {
            commit("SET_UserBalanceRequest", response.data);
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
