<template>
  <div id="data-list-list-view" class="data-list-container">
    <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />

    <vs-table ref="table" v-model="selected" pagination :max-items="itemsPerPage" search :data="testdata">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div class="
              p-4
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            ">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                testdata.length - currentPage * itemsPerPage > 0
                ? currentPage * itemsPerPage
                : testdata.length
              }}
              of {{ testdata.length }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="UserBalanceRequests">{{ $t("FullName") }}</vs-th>
        <vs-th sort-key="UserBalanceRequests">{{ $t("CurrencyName") }}</vs-th>
        <vs-th sort-key="UserBalanceRequests">{{ $t("Amount") }}</vs-th>
        <vs-th sort-key="UserBalanceRequests">{{ $t("RequestDate") }}</vs-th>
        <vs-th sort-key="UserBalanceRequests">{{ $t("DateApproved") }}</vs-th>

        <vs-th>{{ $t("Actions") }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              {{ tr.FullName }}
            </vs-td>
            <vs-td>
              {{ tr.CurrencyName }}
            </vs-td>
            <vs-td>
              {{ tr.Value }}
            </vs-td>
            <vs-td v-if="tr.RequestDate !== '1-01-01'">
              {{ formatDate(tr.RequestDate) }}

            </vs-td>
            <vs-td v-if="tr.DateApproved !== '1-01-01'">
              {{ formatDate(tr.DateApproved) }}
            </vs-td>

            <vs-td class="whitespace-no-wrap">
      <div class="flex space-x-2">
        <!-- First pair of feather-icons -->
        <div class="flex space-x-2">
          <feather-icon
            id="approveBT"
            :disabled="isApproving || tr.IsApproved"
            v-show="!tr.IsApproved"
            icon="CheckSquareIcon"
            svgClasses="w-5 h-5 hover:text-primary stroke-current"
            @click="openApprove(tr)"
          />
          <feather-icon
            id="cancelBT"
            :disabled="isApproving || tr.IsApproved"
            v-show="!tr.IsApproved"
            icon="TrashIcon"
            svgClasses="w-5 h-5 hover:text-primary stroke-current"
            @click="CancelRequest(tr)"
          />
        </div>

        <!-- Second feather-icon -->
        <feather-icon
          icon="EyeIcon"
          v-if="tr.ReceiptImg !== ''"
          :disabled="!tr.ReceiptImg"
          svgClasses="w-5 h-5 hover:text-primary stroke-current"
          @click="viewImage(tr.ReceiptImg)"
        />
      </div>
    </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-popup :active.sync="showImageModal">
      <h5>{{ $t('ReceiptImage') }}</h5>
      <div class="text-center">
        <img :src="selectedImage" alt="Receipt Image" class="max-w-full max-h-full" @error="handleImageError" />
        <p v-if="!selectedImage">{{ $t('NoImageToDisplay') }}</p>
      </div>
    </vs-popup>


  </div>
</template>

<script>
import moduleUserBalanceRequest from "@/store/UserBalanceRequest/moduleUserBalanceRequest.js";
import DataViewSidebar from "./DataViewSidebar.vue";

export default {
  components: {
    DataViewSidebar,
  },
  data() {
    return {
      showImageModal: false,
      isApproving: false,
      selectedImage: null,
      imageLoadError: false,
      selected: [],
      search: {},
      showSearchSidebar: false,
      itemsPerPage: 10,
      isMounted: false,
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      rowDataForDelete: {},
      rowDataApprove: {},
      activeConfirm: false,
      testdata:[]
    };
  },

  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    UserBalanceRequests() {
     if(this.testdata)
      return this.testdata;
      else return  [];
    },
  },

  methods: {
    handleImageError() {
      this.imageLoadError = true;
    },
    viewImage(imageSrc) {
      debugger;
      if (imageSrc) {
        this.selectedImage = imageSrc;
        this.showImageModal = true;
      }
    },
    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },

    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
    deleteData(data) {
      this.$store
        .dispatch("UserBalanceRequestList/DeleteUserBalanceRequest", data)
        .then(() => {
          this.$store
            .dispatch("UserBalanceRequestList/fetchAllUserBalanceRequestList")
            .then(() => {

              this.$vs.loading.close();
            });
        })
        .catch(() => { });
    },

    // ApproveToAdd(data) {
    //   debugger;
    //   this.isApproving = true;
    //   this.$store
    //     .dispatch("UserBalanceRequestList/addItem", data)
    //     .then(() => {
    //       window.showSuccessApproveRequest();
    //       this.getAllRequests();
    //       window.location.reload();
    //     })
    //     .catch(() => {
    //       this.isApproving = false;
    //     });
    // },

    ApproveToAdd(data) {
      debugger;
      if (this.isApproving == true) {
        return; // Exit early if the button is already disabled
      }
      this.isApproving = true;
      const button = document.getElementById("approveBT");
      button.disabled = true; // Disable the button
      this.$store
        .dispatch("UserBalanceRequestList/addItem", data)
        .then((response) => {
          if (response.status == 200){
           window.showSuccessApproveRequest();
          this.getAllRequests();
          window.location.reload();
          }})
        .catch(() => {
          debugger;
            this.isApproving = false;
          window.accepterror();
        })
        .finally(() => {
          this.isApproving = false;
          button.disabled = false; // Re-enable the button
        });
    },
//CancelRequest
CancelRequest(data) {
      this.$store
        .dispatch("UserBalanceRequestList/CaceleClientRequest", data)
        .then((response) => {
          if (response.status == 200){
           window.showCancelRequest();
          this.getAllRequests();
          window.location.reload();
          }})
        .catch(() => {
          debugger;
            this.isApproving = false;
          window.accepterror();
        })
        .finally(() => {
          this.isApproving = false;
          button.disabled = false; // Re-enable the button
        });
    },

    getAllRequests() {
      debugger;
      this.$store
        .dispatch("UserBalanceRequestList/fetchAllUserBalanceRequestList")
        .then((response) => {
          debugger;
          this.testdata = response.data;
          this.$vs.loading.close();
         // return this.$store.state.UserBalanceRequestList.UserBalanceRequests;

        })

        .catch(() => {
          this.$vs.loading.close();
          window.showError();
        });
    },

    // formatDate(date) {
    //   if (date) {
    //     const d = new Date(date);
    //     const year = d.getFullYear();
    //     const month = ('0' + (d.getMonth() + 1)).slice(-2);
    //     const day = ('0' + d.getDate()).slice(-2);
    //     return `${year}-${month}-${day}`;
    //   } else {
    //     return '';
    //   }
    // },
    formatDate(date) {
      const replacedDate = date ? date.replace(/1-01-01/g, ' ') : '';
      if (replacedDate !== '') {
        const d = new Date(replacedDate);
        if (!isNaN(d.valueOf())) {
          const year = d.getFullYear();
          const month = ('0' + (d.getMonth() + 1)).slice(-2);
          const day = ('0' + d.getDate()).slice(-2);
          return `${year}-${month}-${day}`;
        }
      }
      return '';
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },

    openApprove(data) {
      this.rowDataApprove = data;
      // this.$vs.dialog({
      //   type: "confirm",
      //   color: "danger",
      //   title: this.$t("Confirm"),
      //   text: this.$t("AreYouSureYouWantToApproveRequest"),
       this.acceptApprove()
     // });
    },

    acceptApprove() {
      this.ApproveToAdd(this.rowDataApprove);
    },

    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("Areyousureyouwantdeletethisrecord"),
        accept: this.acceptAlert,
      });
    },
    toggleSearchSidebar(val) {
      this.showSearchSidebar = val;
    },

    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "danger",
        title: this.$t("Deletedrecord"),
        text: this.$t("The selected record was successfully deleted"),
      });
    },
  },
  watch: {
    selectedImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.imageLoadError = false;
      }
    }
  },
created() {
    if (!moduleUserBalanceRequest.isRegistered) {
      this.$store.registerModule("UserBalanceRequestList", moduleUserBalanceRequest);
      moduleUserBalanceRequest.isRegistered = true;
    }
    debugger;
    this.$store
      .dispatch("UserBalanceRequestList/fetchAllUserBalanceRequestList")
      .then((result) => {
        //this.$set( this.testdata , result.data);
        this.testdata=result.data;
        this.$vs.loading.close();
      })
      .catch(() => {
        this.$vs.loading.close();
        window.showError();
      });
  },
  //   created() {
  //     debugger;
  //     if (!moduleUserBalanceRequest.isRegistered) {
  //       this.$store.registerModule("UserBalanceRequestList", moduleUserBalanceRequest);
  //       moduleUserBalanceRequest.isRegistered = true;
  //     }
  // debugger;
  //     this.$store
  //       .dispatch("UserBalanceRequestList/fetchAllUserBalanceRequestList")
  //       .then((result) => {
  //         this.$store.state.UserBalanceRequestList.UserBalanceRequests = result.data;
  //         this.$vs.loading.close();
  //       })
  //       .catch(() => {
  //         this.$vs.loading.close();
  //         window.showError();
  //       });
  //   },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      >span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          .product-img {
            height: 70px;
            width: 100px;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
