/*=========================================================================================
  File Name: modulecourseClassMutations.js
  Description: courseClass Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_UserBalanceRequest(state, item) {
    state.UserBalanceRequest.unshift(item);
  },

  SET_UserBalanceRequest(state, UserBalanceRequest) {

    state.UserBalanceRequests =UserBalanceRequest;

  },

  SET_ALLUserBalanceRequest(state, allUserBalanceRequest) {
    state.UserBalanceRequestS = allUserBalanceRequest;
  },

  UPDATE_UserBalanceRequest(state, item) {
    const Index = state.UserBalanceRequests.findIndex(p => p.Id == item.Id);
    Object.assign(state.UserBalanceRequests[Index], item);
  }

};
